.Join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j > hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left-j > div {
    display: flex;
    gap: 1rem;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container {
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
    border-radius: 8px;
}

.email-container input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
    transition: all 0.3s ease;
}

/* Hover and focus styles for the email input */
.email-container input:focus,
.email-container input:hover {
    background-color: rgba(255, 165, 0, 0.1); /* Light orange background */
    border-color: #ff7f50; /* Change border color on hover */
    box-shadow: 0 0 15px rgba(255, 165, 0, 0.4); /* Glowing shadow */
}

/* Placeholder styles */
.email-container input::placeholder {
    color: rgba(255, 165, 0, 0.7);
    transition: all 0.3s ease;
}

.email-container input:focus::placeholder,
.email-container input:hover::placeholder {
    color: rgba(255, 165, 0, 1);
}

/* Default placeholder color */
::placeholder {
    color: var(--lightgray);
}

.btn-j {
    background-color: var(--orange);
    color: white;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.email-container {
  position: relative; /* Position form container relative to place pop-up inside */
}

.popup-message {
  position: absolute;
  top: 110%; /* Adjust to be below the button */
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px 20px;
  background-color: #4caf50; /* Green for success */
  color: white;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
}

.popup-message.error {
  background-color: #f44336; /* Red for error */
}

.btn-j {
  position: relative; /* To anchor the pop-up near the button */
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
    .Join {
        flex-direction: column;
        gap: 1rem;
    }
    
    .left-j {
        font-size: x-large;
        flex-direction: column;
    }
    
    .right-j {
        padding: 2rem;
    }
}
